@use "../../ui-kit/colors.scss" as c;

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.header h2 {
  color: c.$deepest-blue-2;
  font-family: "Syne";
  font-size: 16px;
  line-height: 28px;
  padding: 10px 12px;
  background-color: c.$vibrant-green-7;
  margin: 0;
}
