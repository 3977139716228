@use "components/ui-kit/colors.scss" as c;
@use "components/ui-kit/devices.scss";
@use "./components/ui-kit/overrides.scss";
@import "components/ui-kit/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Syne:wght@400;500;600&display=swap");

.App {
  text-align: center;
  color: c.$color-white;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

a {
  color: c.$new-leaf-green-5;

  &:hover,
  &:focus {
    color: c.$vibrant-green-7;
  }
}

.form-wrapper {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background-color: hsla(0, 0%, 100%, 0.61);

  h3 {
    margin-block-end: 0;
    margin-block-start: 0;
    font-family: "Syne";
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    text-align: left;
    margin-bottom: 0;
    margin-bottom: 60px;
  }

  .form-group-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    position: relative;
    max-width: 400px;
    width: 100%;

    .form-label {
      font-size: 14px;
    }

    input {
      display: inline-block;
      padding: 6px 0;
      width: 100%;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid c.$deepest-blue-8;
      box-sizing: border-box;
    }
  }
}

.see-password {
  color: c.$cool-gray-6;
  position: absolute;
  bottom: 5px;
  right: 15px;
  cursor: pointer;
}

.form-wrapper button,
.default-button {
  margin: 0 10px;
  padding: 0 28px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: c.$new-leaf-green-5;
  color: c.$deepest-blue-2;
  line-height: 50px;
  border-radius: 0;
  border: 1px solid c.$new-leaf-green-5;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;

  &.sm-button {
    font-size: 0.875rem;
    line-height: 40px;
    padding: 0 15px;
  }

  &.button-without-bg {
    background: transparent;
    color: c.$color-white;
  }

  &:hover,
  &:focus {
    background: c.$vibrant-green-7;
    border: 1px solid c.$vibrant-green-7;
    color: c.$deepest-blue-2;
  }

  &.btn.disabled,
  &.btn:disabled {
    background: c.$new-leaf-green-5;
    border: 1px solid c.$new-leaf-green-5;
    color: c.$deepest-blue-2;
  }
}

.error-message {
  padding: 5px;
  color: c.$color-white;
  border-radius: 5px;
  background-color: c.$error-light-red-color;
}

.main-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  width: fit-content;
  padding: 0.5em;
  border: none;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.96px;
  color: c.$deepest-blue-2;
  opacity: 0.8;
  transition: 0.3s ease-in-out;
  background-color: c.$new-leaf-green-5;
  min-width: 100px;
  height: 40px;

  &:disabled {
    opacity: .5 !important;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
  }

  &.cancel-button {
    border: 1px solid c.$color-white;
    background-color: transparent;
    color: c.$color-white;

    &:hover {
      color: c.$deepest-blue-2;
      background-color: c.$color-white;
    }
  }
}

.account-overview-table-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 13px;

  button {
    background: c.$new-leaf-green-5;
    color: c.$deepest-blue-2;
    border-radius: 0;
    padding: 8px 12px;

    span {
      display: flex;
      align-items: center;
    }

    i {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.semi-bold {
  font-weight: 500;
}

.react-tooltip {
  // we use important because this is 3rd party library and it uses its own styles
  background-color: c.$deepest-blue-1 !important;
  border: 1px solid c.$deepest-blue-5 !important;
  border-radius: 0 !important;
  color: c.$color-white !important;
  opacity: 1 !important;
  z-index: 2;
}

.fs-italic {
  font-style: italic;
}

.page-wrapper {
  background-color: c.$deepest-blue-2;
  width: 100%;

  h3 {
    font-family: "Syne";
  }
}

.d-flex {
  display: flex;
}

.notification {
  width: 100%;
  @include fs-12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notification-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.f-gap-20 {
  gap: 20px;
}

.main-checkbox input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox */
.main-checkbox .checkmark {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 1.5px solid c.$new-leaf-green-5;
  vertical-align: middle;
  margin-right: 8px;
  transition: background-color 0.2s, border-color 0.2s;
}

/* Add a checkmark inside the custom checkbox */
.main-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid c.$deepest-blue-2;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.main-checkbox input[type="checkbox"]:disabled + .checkmark {
  border-color: c.$new-leaf-green-3;
  opacity: 0.5;
}

/* When the checkbox is checked, display the checkmark and change the background color */
.main-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: c.$new-leaf-green-5;
  border-color: c.$new-leaf-green-5;
}

.main-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

/* Style the label text */
.main-checkbox {
  cursor: pointer;
  user-select: none;
  font-size: 16px;
}


/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: c.$deepest-blue-2; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: c.$deepest-blue-4; /* Color of the thumb */
  border-radius: 6px; /* Roundness of the thumb */
  border: 3px solid c.$deepest-blue-2; /* Padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: c.$deepest-blue-4 c.$deepest-blue-2; /* Thumb and track color */
}
