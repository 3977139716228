@use "../colors.scss" as c;
@import "../mixins.scss";

.upload-container {
  width: 100%;

  .upload-inner-container {
    display: flex;
  }

  label {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      color: c.$carribean-blue-5;
      font-weight: 500;
      margin-right: 13px;
      @include fs-14;

      &:before {
        // background-image: url("/images/pin.svg");
        width: 20px;
        height: 20px;
        content: "";
        margin-right: 10px;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  input[type="file"] {
    color: transparent;
    overflow: hidden;
    display: none;
  }

  ul {
    margin: 13px 0 -12px 35px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    color: c.$carribean-blue-5;
    font-weight: 500;
    @include fs-14;
    position: relative;
    text-decoration-line: underline;
    display: flex;
    margin-right: 15px;
    margin-bottom: 12px;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;

    &:after {
      // background-image: url("/images/cancel.svg");
      width: 20px;
      height: 20px;
      content: "";
      margin-left: 5px;
      display: inline-block;
    }
  }
}