@use "../../ui-kit/colors.scss" as c;
@import "../../ui-kit/mixins.scss";

.primary,
.zimtra-primary {
  position: relative;
  color: c.$color-white;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  font-size: 16px;
  font-weight: 400;

  &.shortWidth {
    width: fit-content;
  }

  hr {
    background: c.$cool-gray-9;
    height: 1px;
    width: 100%;
    margin: 4px 0;
    border: none;
  }
}

.zimtra-primary {
  font-family: "Montserrat";
}

.dropdownMenu {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

.dropdownMenuIcon {
  position: absolute;
  display: flex;
  right: 16px;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translate(0, -50%);
  gap: 10px;

  &.disabled {
    cursor: not-allowed;
  }

  &.with-alert {
    top: 50%;
  }
}

.primary .errorStyle span {
  color: c.$red-vivid-5;
}

.zimtra-primary .errorStyle span {
  color: c.$red-vivid-9;
}

.optionsBlock div span {
  position: relative;
  z-index: 2;
}

.primary .dropdownField,
.zimtra-primary .dropdownField {
  padding: 13px 52px 13px 16px;
  border: 1px solid c.$deepest-blue-4;
  background: c.$deepest-blue-2;
  min-height: 45px;
  box-sizing: border-box;
  text-align: left;

  &.placeholder {
    color: c.$cool-gray-5;
  }

  &:hover {
    border: 1px solid c.$cool-gray-7;
  }

  &.disabled {
    cursor: not-allowed;

    span {
      color: c.$cool-gray-5;
    }
  }

  &.opened {
    border: 2px solid c.$teal-5;
  }

  &.error {
    border: 1px solid c.$red-vivid-5;
    padding-right: 85px;
  }
}

.zimtra-primary .dropdownField {
  border-radius: 0;
  border: 1px solid c.$deepest-blue-4;
  background-color: c.$deepest-blue-2;
  color: c.$color-white;

  &:hover {
    border-bottom: 2px solid c.$deepest-blue-6;
  }

  &.opened {
    border: 1px solid c.$deepest-blue-4;
    border-bottom: 2px solid c.$carribean-blue-5;
  }

  &.error {
    border: 1px solid c.$deepest-blue-4;
    border-bottom: 2px solid c.$red-vivid-5;
    padding-right: 85px;
  }
}

.primary .optionsBlock,
.zimtra-primary .optionsBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  padding: 4px;
  box-shadow: rgba(0 0 0 / 24%) 0 3px 8px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 120px;
  z-index: 9999;
  background: c.$deepest-blue-4;
  box-sizing: border-box;
  overflow: auto;

  div {
    position: relative;
    @include fs-14;
    width: -webkit-fill-available;
    padding: 8px 12px;
    min-height: 33px;
    text-align: initial;
    overflow: hidden;
    border-bottom: 2px solid transparent;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      background-color: #DCEEFB1F;
    }

    &:active {
      background-color: c.$cool-gray-8;
    }

    .chosen {
      position: absolute;
      right: 7px;
      top: 50%;
      padding-right: 0;
      transform: translate(0, -50%);
      line-height: 0;
      text-align: right;
      z-index: 1;
    }
  }
}

.zimtra-primary .optionsBlock {
  border-radius: 0;
  border: 1px solid c.$deepest-blue-4;
  background-color: c.$deepest-blue-2;
  color: c.$color-white;

  div {
    border-radius: 0;

    &:hover {
      background-color: initial;
      border-bottom: 2px solid c.$carribean-blue-5;
    }

    i {
      font-size: 24px;
      color: c.$vibrant-green-7;
    }
  }
}

.errorStyle {
  position: absolute;
  display: block;
  left: 0;
  top: 100%;
  font-size: 12px;
  margin-top: 3px;
}

.primary .withAlert, .zimtra-primary .withAlert {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);

  .icon-wizard-alert_circle {
    font-size: 24px;
    color: c.$red-vivid-6;
  }
}

.zimtra-primary .withAlert {
  i {
    font-size: 24px;

    &:first-child {
      color: c.$red-vivid-5;
    }
  }
}

.primary .arrowIcon {
  color: c.$cool-gray-6;
  font-size: 20px;
}

.zimtra-primary .arrowIcon {
  color: c.$cool-gray-6;
  font-size: 24px;
}
