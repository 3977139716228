@use "components/ui-kit/colors.scss" as c;
@import "components/ui-kit/mixins.scss";

.confirm-modal {
  min-width: 540px;

  .modal-inner-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 63px 0;

    form {
      padding-bottom: 20px;

      hr {
        color: c.$deepest-blue-4;
      }
    }
  }

  .current-price-row,
  .change-price-row,
  .title {
    margin-bottom: 23px;
  }

  .left-side {
    width: 30%;
  }

  .type-eod-row {
    >div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      label {
        width: 50%;
      }
    }
  }

  button {

  }
}