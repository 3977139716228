@use "../../ui-kit/colors.scss" as c;
@use "../../ui-kit/devices.scss";
@import "../../ui-kit/mixins.scss";

.account-overview-wrapper {
  padding: 20px;
  font-size: 1rem;
  width: 100%;
  overflow: auto;
}

.account-overview-header {
  line-height: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 23px;

  h3 {
    color: c.$cool-gray-10;
    font-weight: bold;
    text-align: initial;
    font-family: "Syne";
    @include fs-20;
    letter-spacing: 0.4px;

    @include devices.mobile {
      @include fs-16;
      line-height: 16px;
    }
  }

  span {
    display: flex;
    color: c.$deepest-blue-8;
    @include fs-14;

    @include devices.mobile {
      @include fs-10;
    }

    &.semi-bold {
      color: c.$color-white;
    }
  }

  .account-overview-header-top {
    h3 {
      margin-bottom: 0;
    }

    @include devices.mobile {
      gap: 5px;
    }

    span {
      font-family: "Syne";
      font-weight: 600;
      padding: 4px 8px;
      font-size: 20px;
      line-height: 22px;
      color: c.$color-white;

      @include devices.mobile {
        padding: 2px 4px;
        @include fs-16;
      }

      &.pro-acc {
        background-color: c.$carribean-blue-4;
      }

      &.elite-acc {
        background-color: c.$zimtra-purple-1;
      }
    }
  }
}

.account-summary-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include fs-16;
  
  h6 {
    font-family: "Syne";
    color: c.$deepest-blue-8;
    @include fs-16;
  }

  img {
    cursor: pointer;
    margin-left: 6px;
  }
}

.account-summary-body {
  display: grid;
  grid-template-columns: repeat(auto-fill, 255px);
  grid-gap: 15px;
  width: 100%;
  max-width: 800px;

  @include devices.mobile {
    grid-template-columns: repeat(auto-fill, 48%);
    gap: 0.5rem;
  }
}

.account-overview-table {
  padding-top: 40px;
  position: relative;
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include devices.mobile {
    overflow: auto;
  }

  button {
    margin: 0;
    margin-bottom: 20px;
  }

  .no-wrap-cell {
    white-space: nowrap;
  }
}

.account-overview-table-body {
  min-height: 166px;
  width: 100%;
  position: relative;
  overflow: auto;
}

.row-overlay-menu {
  div {
    &:last-child {
      border-radius: 0;
      background-color: c.$deepest-blue-2;

      @include devices.desktop {
        min-width: 250px;
      }

      @include devices.tablet {
        min-width: 250px;
      }
    }
  }

  i {
    color: c.$deepest-blue-7;
    transform: rotate(90deg);
  }
}

.table-wrapper {
  height: fit-content;
  width: 100%;
  @include fs-12;
  text-align: left;

  thead {
    @include fs-12;

    tr {
      th {
        background-color: c.$deepest-blue-1;
        padding: 0 12px !important;

        &:first-child {
          text-transform: uppercase;
          min-width: 70px; //is set for ID column to have constant width to prevent shaking during sorting
        }

        &:not(:first-child):not(:last-child) {
          border-left: 1px solid c.$deepest-blue-4;
          border-right: 1px solid c.$deepest-blue-4;
        }
      }
    }
  }

  tbody {
    tr {
      background-color: c.$deepest-blue-3;

      &:hover {
        background-color: c.$deepest-blue-4;

        td {
          border-color: c.$deepest-blue-3;
        }
      }
    }

    td {
      border: 1px solid c.$deepest-blue-4;
      padding: 12px !important;
    }
  }
}

.this-month-body-item {
  background-color: c.$deepest-blue-3;
  color: c.$color-white;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 1.1em;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px 14px;

  @include devices.mobile {
    padding: 10px 10px 14px;
  }

  span:first-child {
    color: c.$deepest-blue-8;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 2px;

    @include devices.mobile {
      font-size: 0.75rem;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }

  span {
    color: c.$deepest-blue-9;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 36px;

    @include devices.mobile {
      font-size: 0.875rem;
      line-height: normal;
    }
  }
}
