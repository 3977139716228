@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/components/ui-kit/mixins.scss";

$small-screen: 800px;
$mobile-screen: 400px;

.documents-block {
  width: 100%;
  overflow: auto;
  padding: 0 10px 30px;

  .lvl-1-2-block {
    display: flex;
    flex-direction: column;
    min-width: 25vw;
    max-width: 400px;
    overflow: auto;
    padding: 3rem 1rem 8rem;
    background-color: rgb(245, 247, 249);

    .lvl-1-block {
      height: 40%;

      .first-lvl-wrapper {
        .other-level-elements {
          color: #5f5f5f;
          cursor: pointer;
          padding-left: 1rem;
          text-align: left;

          &:hover {
            background: #e5e9ed;
          }
        }
      }
    }

    .lvl-2-block {
      height: 60%;

      .other-level-elements {
        color: #5f5f5f;
        cursor: pointer;
        padding-left: 1rem;
        text-align: left;

        &:hover {
          background: #e5e9ed;
        }
      }
    }

    .divider {
      margin-left: 10px;
    }

    .picked {
      width: 100%;
      border-left: 5px solid c.$accent-color;
      background: #d9dcde;
      text-align: start;
    }
  }

  .parent {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem;
    color: #0d2b3e;
    text-decoration: none;
    text-align: start;

    span {
      text-align: initial;
    }

    &:hover {
      background: #e5e9ed;
    }
  }

  blockquote {
    padding: 10px 10px 1px 13px;
    border-left: 8px solid c.$new-leaf-green-5-primary;
    background: c.$deepest-blue-3;
  }

  .lvl-3-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;

    .lvl-3-main-content {
      width: 100%;
      height: auto;
      min-height: auto;
      margin: 0;

      @include devices.desktop {
        min-height: 10vh;
      }

      .breadcrumb-edit-button-block {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .document-edit-dropdown {
          .dropdown-menu.show {
            z-index: 3;
          }

          button {
            color: rgb(204, 204, 204);
            font-size: 2rem;
            margin-top: 10px;
            background-color: transparent;
            border: none;

            &:focus {
              border: none;
              box-shadow: 0 0 0 0.25rem c.$accent-color;
            }
          }

          .dropdown-toggle::after {
            content: none;
          }
        }
      }

      .breadcrumb {
        .breadcrumb-item {
          padding-left: 0;
          text-align: left;

          a {
            text-decoration: none;

            :first-child {
              color: c.$new-leaf-green-5-primary;
              transition: color 0.2s;
              font-size: 14px;
              font-weight: 400;
              text-decoration: underline;
            }

            .breadcrumb-divider {
              padding: 0 6px;
              color: c.$accent-color-text;
              font-weight: 400;
              font-size: 14px;

              &:hover {
                border-bottom: none;
              }
            }
          }

          &::before {
            content: "";
          }
        }

        .breadcrumb-dots {
          :first-child {
            color: c.$accent-color;
            transition: color 0.2s;
            font-weight: 600;
            cursor: pointer;

            &:hover {
              border-bottom: c.$accent-color solid 2px;
            }
          }

          :nth-child(2) {
            padding: 0 0.5rem;
            color: #6c757d;
            font-weight: 600;
          }
        }
      }

      .lvl-3-summary {
        width: 100%;
        margin: 0;
        text-align: start;
        font-family: 'Syne';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        color: c.$cool-gray-10;

        @include devices.desktop {
          margin: 18px 0 0 0;
        }
      }

      .lvl-3-body {
        height: inherit;
        width: 100%;
        overflow: auto;
        text-align: left;
        color: c.$deepest-blue-9;

        h2 {
          @include fs-16;
          text-transform: uppercase;
        }

        .accordion {
          margin: 25px 0;

          .accordion-item {
            background: transparent;
            border-bottom: c.$accent-color solid 1px !important;

            .accordion-button {
              color: c.$white;
              padding: 18px 15px;
              background-color: c.$deepest-blue-2-primary;
              box-shadow: none;
    
              &:not(.collapsed) {
                background-color: c.$new-leaf-green-5-primary;
                color: c.$deepest-blue-2-primary;
                font-weight: 600;
    
                &::after {
                  filter: grayscale(1);
                }
              }
    
              &:focus {
                box-shadow: none;
              }
            }
    
            .accordion-collapse {
              color: c.$white;
    
              &.show {
    
                .accordion-button {
                  border: 0;
                }
    
                .accordion-body {
                  background: c.$deepest-blue-2-primary;
                }
              }
            }
          }

          .accordion-body {
            padding: 16px 0;

            @include devices.desktop {
              padding: 16px 30px;
            }
          }
         
        }


        h3#recent,
        h4#current {
          @include fs-16;
          padding-top: 10px;
        }

        table {
          width: 100%;

          tbody {
            vertical-align: top;

            tr {
              td {
                padding: 4px 8px 20px;
              }

              &:hover {
                background-color: c.$deepest-blue-3;
              }
            }
          }

          thead {
            tr {
              th {
                font-size: 14px;
                line-height: 18px;
                padding: 8px;
              }

              &:first-child {
                border-top: 0;
              }
            }
          }

          tr {
            border-top: 2px solid c.$deepest-blue-3;
            border-bottom: 2px solid c.$deepest-blue-3;
          }
        }

        code {
          color: c.$red-vivid-6;
        }

        p {
          @include fs-14;
          margin-bottom: 12px;
        }

        img {
          border-radius: 0;
          border: 0;
          max-width: 100%;
          margin: 20px 0 10px;
        }
      }
    }

    .lvl-3-additional-content {
      height: 100%;
      border-top: 8px solid c.$vibrant-green-7-primary;
      margin: 0;
      width: 100%;
      background: c.$deepest-blue-3;

      @include devices.desktop {
        margin: 0;
      }

      @include devices.desktop-large {
        margin: 1rem 0;
      }
    }
  }

  .edit-document-area {
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
    height: 80%;
    padding: 5rem 3rem 1rem;
    margin-top: 100px;
    align-items: center;
    top: 0;
    left: 50%;
    background-color: white;
    transform: translateX(-50%);
    z-index: 12;
    border-radius: 20px;

    .edit-document-edit-block {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;

      .edit-document-edit-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 5px;

        .dropdown-label {
          text-align: initial;
          font-size: 0.8rem;
        }

        input {
          margin: 0;
          padding: 0 10px;
          height: 50px;
        }

        .document-edit-dropdown {
          position: relative;

          button {
            background-color: c.$accent-color;
            border: none;
          }

          .btn-check:focus+.btn-primary,
          .btn-primary:focus {
            box-shadow: none;
          }

          .btn-primary:active:focus,
          .show>.btn-primary.dropdown-toggle:focus {
            box-shadow: none;
          }
        }
      }
    }

    textarea {
      min-height: 50vh;
      max-height: 70vh;
      width: 100%;
      padding: 1rem;
      margin: 0 2rem 2rem;
    }

    input {
      width: 100%;
      padding: 10px;
      margin: 0.5rem;
    }

    .edit-document-button-block {
      display: flex;
      position: relative;

      button {
        margin: 20px 10px;
        padding: 10px 30px;
        border: none;
        background-color: rgb(180, 175, 175);

        &:hover {
          color: c.$accent-color;
        }
      }

      .delete-button {
        position: absolute;
        right: 0;
        background-color: indianred;
        transition: 0.5s ease-in-out;
        padding: 0;

        &:hover {
          background-color: rgb(201, 55, 55);
          color: white;
        }
      }
    }
  }

  .delete-area {
    height: auto;
    width: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
  }

  .black-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.308);
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 80%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 10px;

    &:hover {
      background-color: #838383;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 10px;
    margin: 2em 0;
  }
}