@use "components/ui-kit/colors.scss" as c;
@import "/src/components/ui-kit/mixins.scss";

.onboarding-modal-body,
.onboarding-modal-body-exchange {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 40px 60px 20px 60px;

  hr {
    color: c.$deepest-blue-4;
    opacity: 1;
  }
}

.onboarding-modal-body-exchange {
  gap: 0;

  p {
    margin: 10px 0;
    &:first-child {
    }
  }
}

.onboarding-modal-footer {
  padding: 0 60px 40px;
}

.title {
  text-transform: uppercase;
  @include fs-16;
  margin-bottom: 20px;
}

.overnight {
  &.disabled {
    opacity: 0.5;
  }
}

.riskItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :nth-child(2) {
    width: 50%;
  }
}
