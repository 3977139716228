@use "../ui-kit/colors.scss" as c;
@import "/src/components/ui-kit/mixins.scss";

.notification {
  max-width: 260px;
  display: flex;
  padding: 8px 8px 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border: 1px solid c.$deepest-blue-5;
  background: rgba(6, 9, 34, 0.5);
  /* blur+shadow */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(12.5px);
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @include fs-14;
    font-weight: 500;

    img {
      cursor: pointer;
      &:hover {
        background-color: c.$deepest-blue-5;
      }
        &:last-child {
            margin-left: 10px;
        }
    }
}