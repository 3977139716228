@use "../../ui-kit/colors.scss" as c;

.radioButtonBlock {
  display: flex;
  gap: 1em;
  color: c.$color-white;
  font-size: 14px;

  &.column {
    flex-direction: column;
    align-items: flex-start;
  }
}

.radioButtonItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  text-align: left;

  span {
    position: relative;
    padding-left: 30px;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &::before {
      top: 50%;
      left: 0;
      margin-right: 5px;
      width: 18px;
      height: 18px;
      border: 2px solid c.$new-leaf-green-5;
      background: transparent;
      transform: scale(1) translateY(-50%);
    }

    &::after {
      width: 10px;
      height: 10px;
      background-color: c.$new-leaf-green-5;
      top: 50%;
      left: 1px;
      opacity: 0;
      transform: scale(0);
      // transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
  }

  .radio-input {
    display: none;

    &:checked+span::after {
      opacity: 1;
      transform: scale(1) translate(30%, -50%);
    }

    &:checked+span::before {
      border: 2px solid c.$new-leaf-green-5;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .radio-input:not(:checked)+span {
    &:hover::after {
      width: 18px;
      height: 18px;
      left: -5px;
      opacity: 1;
      transform: scale(1) translate(30%, -50%);
      transition: none;
    }

    &:active::after {
      width: 18px;
      height: 18px;
      left: -5px;
      opacity: 1;
      transform: scale(1) translate(30%, -50%);
      transition: none;
    }
  }
}