@use "colors.scss" as c;
@import "mixins.scss";

.radio-group-container {
  input+span {
    color: c.$white;
    @include fs-14;
  }
}

.zimtra-h2 {
  font-weight: 600;
}

.error-text {
  color: c.$red-vivid-8;
  @include fs-12;
  margin-top: 3px;
}

.error-field {
  line-height: 0;

  .cabinet-input {
    input {
      border-color: c.$red-vivid-5 !important;
    }
  }

  .cabinet-textarea {
    border-color: c.$red-vivid-5 !important;
  }

  >* {
    line-height: initial;
  }
}

