@use "../ui-kit/colors.scss" as c;

.headerBlock {
    background-color: c.$deepest-blue-1;
    min-height: 100px;
    border-top: 8px solid c.$new-leaf-green-5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 72px;

    img {
        cursor: pointer;
        height: max-content;
    }
}

.logout {
    margin: 0 !important;
}