@use "../../ui-kit/colors.scss" as c;

.mainBlock {
  background: linear-gradient(
    to top,
    #5ecaf8 0%,
    #5fcbf3 13.75%,
    #60cfe3 31.13%,
    #63d6c9 50.37%,
    #67deaa 68.61%,
    #78f0c7 100%
  );
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 600px;
  padding: 0 40px;
}

.form-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: c.$deepest-blue-2;

  .spaced-between {
    display: flex;
    justify-content: space-between;
  }

  .form-checkbox {
    a {
      margin-left: 10px;
    }
  }
}

.info-wrapper {
  padding: 0 10px;
}

.info-block {
  text-align: left;
  position: relative;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.info {
  color: c.$deepest-blue-2;
}

.errorMessage {
  position: absolute;
  bottom: -24px;
  left: 0;
  font-size: 12px;
  color: c.$red-vivid-5;
}