@mixin fs-10 {
  font-size: 10px;
  line-height: 12px;
}

@mixin fs-12 {
  font-size: 12px;
  line-height: 16px;
}

@mixin fs-14 {
  font-size: 14px;
  line-height: 20px;
}

@mixin fs-16 {
  font-size: 16px;
  line-height: 22px;
}

@mixin fs-18 {
  font-size: 18px;
  line-height: 24px;
}

@mixin fs-20 {
  font-size: 20px;
  line-height: 32px;
}

@mixin fs-28 {
  font-size: 28px;
  line-height: 32px;
}
