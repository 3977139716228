@use "../ui-kit/colors.scss" as c;
@import "/src/components/ui-kit/mixins.scss";

.page-layout-wrapper {
  display: grid;
  grid-template-columns: minmax(260px, 360px) 1fr;
  position: relative;
}

.notificationList {
  position: absolute;
  max-height: calc(100vh - 200px);
  overflow: hidden;
  top: 40px;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}