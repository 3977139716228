@use "../colors.scss" as c;
@import "../mixins.scss";

.pagination-wrapper {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  align-self: center;

  .vertical-hr {
    width: 2px;
    height: 12px;
    margin: 0;
    opacity: 1;
    background-color: c.$deepest-blue-5;
    border: none;
  }

  span {
    @include fs-12;

    &:nth-child(3),
    &:nth-child(5) {
      cursor: pointer;
      color: c.$new-leaf-green-5;
    }
  }
}