@use "../../ui-kit/colors.scss" as c;

.onboarding {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -45px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 260px;
    margin: 0;
    margin-bottom: 20px;

    img {
      width: 20px;
    }
  }
}

.table-body {
  display: flex;
  max-width: calc(100vw - 300px);
  max-height: calc(100vh - 260px);
  overflow: auto;
  position: relative;
  min-height: 150px;

  table {
    height: fit-content;
  }

  tbody {
    tr:nth-of-type(even) {
      background-color: c.$deepest-blue-2;

      &:hover {
        background-color: c.$deepest-blue-4;
      }
    }
  }
}
