@use "../../ui-kit/colors.scss" as c;


.animationBase {
  animation-duration: 200ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.primary,
.zimtra-primary {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 100;
}

.closeIcon {
  position: absolute;
  top: 14px;
  right: 17px;
  font-size: 24px;
  cursor: pointer;
  color: c.$cool-gray-6;
}

.base-modal {
  margin: 20px;
  max-width: 800px;
  overflow-x: scroll;
  max-height: calc(100% - 100px);
  box-shadow: 0 0 2px rgba(31 41 51 / 8%), 0 10px 20px rgba(31 41 51 / 10%), 0 2px 4px rgba(31 41 51 / 6%);
  border-radius: 8px;
  background: c.$cool-gray-10;
  position: relative;
  z-index: 101;

  &.visible {
    animation: .2s modal-appear ease-in-out both;
  }
}

.zimtra-primary .base-modal {
  color: c.$color-white;
  background: c.$deepest-blue-2;
  border-radius: 0;
  overflow-x: hidden;
}

@keyframes modal-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}