@use "../../ui-kit/colors.scss" as c;


.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: 100;
}

.blur {
  backdrop-filter: blur(4px);
  pointer-events: none;
}

.colored {
  background-color: c.$cool-gray-3;
  animation: .2s appear ease-in-out both;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}
