@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/components/ui-kit/mixins.scss";


.navlink-wrapper {
  position: relative;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-align: start;
  text-decoration: none;
  color: c.$color-white;
  border-left: 4px solid transparent;
  padding-right: 20px;
  @include fs-16;

  &.active-link {
    background-color: c.$deepest-blue-3;
    border-left: 4px solid c.$vibrant-green-7;
    color: c.$new-leaf-green-5;
  }

  span {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-wrap: break-word;
    padding: 12px 20px;

    img {
      margin-right: 10px;
    }
  }

  a {
    text-decoration: none;
    color: rgb(121, 121, 121);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;

    img {
      margin-right: 5px;
    }
  }

  .navlink-lower-blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
  }

  &:hover:not(.active-link) {
    cursor: pointer;
    background-color: c.$deepest-blue-2;
  }
}

.blacked-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.243);
  z-index: 8;
}

.side-block {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 100px);
  overflow: auto;
  background-color: c.$deepest-blue-1;
  border-top: 1px solid c.$deepest-blue-3;

  @include devices.mobile {
    width: 100%;
    min-width: 320px;
  }

  @include devices.desktop {
    display: flex;
  }

  &.opened-menu {
    display: flex;
    position: absolute;
    z-index: 9;
  }

  .fixed-side-elements {
    display: flex;
    flex-direction: column;
    width: 100%;

    .block-content {
      pointer-events: none;
      opacity: 0.5;
    }

    .navlink-wrapper {
      position: relative;
      min-height: 55px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;
      gap: 10px;
      text-align: start;
      text-decoration: none;
      color: c.$color-white;
      border-left: 4px solid transparent;
      padding-right: 20px;
      @include fs-16;

      &.active-link {
        background-color: c.$deepest-blue-3;
        border-left: 4px solid c.$vibrant-green-7;
        color: c.$new-leaf-green-5;
      }

      span {
        width: 100%;
        overflow-wrap: break-word;
        padding: 12px 20px;

        img {
          margin-right: 10px;
        }
      }

      a {
        text-decoration: none;
        color: rgb(121, 121, 121);
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;

        img {
          margin-right: 5px;
        }
      }

      .navlink-lower-blocker {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 1;
      }

      &:hover:not(.active-link) {
        cursor: pointer;
        background-color: c.$deepest-blue-2;
      }
    }

    .upper-side {
      display: flex;
      flex-direction: column;
      min-height: 40%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 15px;
    }

    .divider {
      margin-left: 10px;
    }

    .lower-side {
      text-align: initial;
      overflow: auto;

      .child-block {
        background-color: #8080800f;
        width: 100%;

        .child-wrapper {
          overflow-wrap: break-word;
          text-decoration: none;
          color: c.$color-white;
          cursor: pointer;
          padding: 5px;
          padding-left: 10px;

          &:hover {
            background-color: #555555;
            color: white;
            cursor: pointer;
            border-radius: 10px;
            transition: 0.75s cubic-bezier(0.25, 1, 0.2, 1);
            padding-bottom: 7px;

            &::marker {
              color: c.$new-leaf-green-5;
            }
          }
        }
      }
    }
  }

  .copyright {
    padding: 0 5px 24px;
    z-index: 12;
    overflow-wrap: break-word;
    color: c.$deepest-blue-7;
    @include fs-12;

    a {
      color: inherit;
      text-decoration: none;
      align-self: flex-start;
    }

    span {
      align-self: flex-start;
      margin-left: 10px;
    }
  }
}

.fixed-side-elements {
  display: flex;
  flex-direction: column;
  width: 100%;

  .block-content {
    pointer-events: none;
    opacity: 0.5;
  }

  .navlink-wrapper {
    position: relative;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
    text-decoration: none;
    color: c.$color-white;
    border-left: 4px solid transparent;
    padding-right: 20px;
    @include fs-16;

    &.active-link {
      background-color: c.$deepest-blue-3;
      border-left: 4px solid c.$vibrant-green-7;
      color: c.$new-leaf-green-5;
    }

    span {
      width: 100%;
      overflow-wrap: break-word;
      padding: 12px 20px;

      img {
        margin-right: 10px;
      }
    }

    a {
      text-decoration: none;
      color: rgb(121, 121, 121);
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;

      img {
        margin-right: 5px;
      }
    }

    .navlink-lower-blocker {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 1;
    }

    &:hover:not(.active-link) {
      cursor: pointer;
      background-color: c.$deepest-blue-2;
    }
  }

  .upper-side {
    display: flex;
    flex-direction: column;
    min-height: 40%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .divider {
    margin-left: 10px;
  }

  .lower-side {
    text-align: initial;
    overflow: auto;

    .child-block {
      background-color: #8080800f;
      width: 100%;

      .child-wrapper {
        overflow-wrap: break-word;
        text-decoration: none;
        color: c.$color-white;
        cursor: pointer;
        padding: 5px;
        padding-left: 10px;

        &:hover {
          background-color: #555555;
          color: white;
          cursor: pointer;
          border-radius: 10px;
          transition: 0.75s cubic-bezier(0.25, 1, 0.2, 1);
          padding-bottom: 7px;

          &::marker {
            color: c.$new-leaf-green-5;
          }
        }
      }
    }
  }
}

.upper-side {
    display: flex;
    flex-direction: column;
    min-height: 40%;
    overflow-y: auto;
    overflow-x: hidden;
  }