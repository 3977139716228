@use "../../ui-kit/colors.scss" as c;
@import "../../ui-kit/mixins.scss";

.primary,
.zimtra-primary {
  position: relative;
  color: c.$cool-gray-1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--fontInter);

  &.short {
    width: fit-content;
  }
}

.zimtra-primary {
  font-family: "Montserrat";
  color: c.$color-white;

  &.disabled {
    color: c.$deepest-blue-8;
  }
}

.labelBlock {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  font-size: 14px;
  line-height: 20px;
}

.rightLabel {
  color: c.$carribean-blue-5;
  border-bottom: 1px solid c.$carribean-blue-5;
  font-weight: 500;
  cursor: pointer;
  align-self: end;
}

.primary input {
  display: inline-block;
  padding: 13px 16px;
  width: 100%;
  font-size: 16px;
  font-family: var(--fontInter);
  border: 1px solid c.$cool-gray-8;
  border-radius: 8px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid c.$cool-gray-7;
  }

  &:focus-visible {
    outline: none;
    border: 2px solid c.$teal-5;
    padding: 12px 15px;
  }

  &:disabled {
    color: c.$cool-gray-5;
    cursor: not-allowed;
    background-color: c.$cool-gray-9;
    border: 1px solid c.$cool-gray-8;
  }

  &.errorInput {
    border: 1px solid c.$red-vivid-5;
    padding-right: 45px;
  }
}

.zimtra-primary .wrapper {
  position: relative;
}

.zimtra-primary .wrapperBorder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: c.$deepest-blue-4;
}

.zimtra-primary input {
  display: inline-block;
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-family: "Montserrat";
  border: 1px solid c.$deepest-blue-4;
  background-color: c.$deepest-blue-2;
  box-sizing: border-box;
  color: c.$color-white;

  &:not(:disabled):not(:focus-visible):not(.errorInput):not(.checkedState):hover+.wrapperBorder {
    background-color: c.$deepest-blue-6;
    height: 2px;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus-visible+.wrapperBorder {
    background-color: c.$carribean-blue-4;
    height: 2px;
  }

  &:disabled {
    color: c.$deepest-blue-8;
    background-color: transparent;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.errorInput,
  &.checkedState {
    padding-right: 45px;
  }

  &.errorInput+.wrapperBorder {
    background-color: c.$red-vivid-5;
    height: 2px;
  }

  &.checkedState+.wrapperBorder {
    background-color: c.$vibrant-green-7;
    height: 2px;
  }
}

.hintStyle {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  line-height: 18px;

  span {
    color: c.$deepest-blue-9;
  }
}

.errorStyle {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 3px;
  font-size: 12px;
  line-height: 18px;
}

.primary .errorStyle span {
  color: c.$red-vivid-5;
}

.zimtra-primary .errorStyle span {
  color: c.$red-vivid-9;
}

.errorIcon,
.checkIcon {
  position: absolute;
  right: 15px;
  bottom: 8px;
  color: c.$red-vivid-6;

  i {
    font-size: 24px;
  }
}

.zimtra-primary .errorIcon {
  color: c.$red-vivid-5;
}

.zimtra-primary .checkIcon {
  color: c.$vibrant-green-7;
}