@use "../colors.scss" as c;
@import "../mixins.scss";

.body-default {
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: c.$cool-gray-1;
  font-weight: 500;

  i {
    color: c.$cool-gray-6;
    font-size: 24px;
  }
}

.primary,
.zimtra-primary {
  display: flex;
  width: min-content;
  height: fit-content;
  position: relative;
  cursor: pointer;

  i {
    border-radius: 4px;

    &:hover {
      background-color: c.$cool-gray-9;
    }

    &:active {
      background-color: c.$cool-gray-8;
    }
  }
}

.zimtra-primary {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  i {
    border-radius: 0;
    color: c.$carribean-blue-5;
    font-size: 24px;

    &:hover {
      background-color: rgb(55, 186, 243, 0.12);
    }

    &:active {
      background-color: rgb(55, 186, 243, 0.24);
    }
  }
}

.zimtra-primary.profileStyle {
  i {
    font-size: 16px;
    color: c.$color-white;
  }
}

.title-menu {
  position: absolute;
  border: 1px solid c.$deepest-blue-3;
  width: 250px;
  top: 2em;
  right: 0;
  background-color: c.$deepest-blue-3;
  padding: 8px;
  box-shadow: 0 2px 4px 0 c.$shadow-gray-color-1,
    0 10px 20px 0 c.$shadow-gray-color-2,
    0 0 2px 0 c.$shadow-gray-color-3;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    button {
      padding: 12px;
      border-radius: 8px;
      border: none;
      width: 100%;
      cursor: pointer;
      background-color: initial;
      font-family: "Montserrat";
      margin-bottom: 0;

      &.withDivider {
        border-bottom: 1px solid black;
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }

      &:not(:disabled) {
        &:hover {
          background-color: c.$cool-gray-9;
        }

        &:active {
          background-color: c.$cool-gray-8;
        }
      }
    }
  }
}

.zimtra-primary .title-menu {
  background-color: c.$deepest-blue-2;
}

.zimtra-primary .title-menu ul button {
  border-radius: 0;
  color: c.$color-white;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      background-color: rgb(55, 186, 243, 0.12);
    }

    &:active {
      background-color: rgb(55, 186, 243, 0.24);
    }
  }
}

.left-side {
  left: 0;
}

.top-side {
  top: 0;
}

.bottom-side {
  bottom: 0;
}

.profileOption {
  font-family: "Montserrat";
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid black;

  p {
    color: c.$color-white;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}

.initialBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;

  span {
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: c.$deepest-blue-2;
    background: linear-gradient(
      to right,
      c.$zimtra-carribean-blue-3,
      c.$zimtra-carribean-blue-4,
      c.$zimtra-carribean-blue-5,
      c.$zimtra-carribean-blue-6,
      c.$new-leaf-green-5);
    border-radius: 50%;
  }
}
