@use "../../ui-kit/colors.scss" as c;
@import "/src/components/ui-kit/mixins.scss";

.listItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    cursor: pointer;
    transform: 0.3s ease-in-out;
  
    &:hover {
      background-color: c.$deepest-blue-5;
    }
  }

  img {
    width: 16px;
    height: 16px;
  }
}
