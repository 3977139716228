@use "components/ui-kit/colors.scss" as c;

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.flex-col {
  flex-direction: column;
}

.calendar-label {
  > div {
    > input {
      transition: 0.3s linear;

      &:focus,
      &:hover {
        border: 1px solid c.$cool-gray-7;
      }
    }

    > span {
      display: none;
    }
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.j-c-space-between {
  justify-content: space-between;
}

.new-request-modal-PE {
  width: 680px;
}