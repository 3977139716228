@use "../../ui-kit/colors.scss" as c;
@import "/src/components/ui-kit/mixins.scss";

.cabinet-modals {

  .acc-status-text {
    @include fs-14;
    color: c.$deepest-blue-9;
  }

  .cabinet-input {
    input {
      transition: 0.3s linear;
  
      &+span {
        display: none;
      }
  
      &::placeholder {
        color: c.$deepest-blue-7;
      }
    }
  
    &:focus,
    &:hover {
      input {
        border: 1px solid c.$cool-gray-7;
      }
    }
  }

  .cabinet-textarea {
    transition: 0.3s linear;
    background: transparent;
    border: 1px solid c.$deepest-blue-4;
    padding: 12px 13px;
    resize: none;
    color: c.$white;
    outline: none;
  
    &::placeholder {
      color: c.$deepest-blue-7;
    }
  
    &:focus,
    &:hover {
      border: 1px solid c.$cool-gray-7;
    }
  }

  &.new-request-modal,
  &.risk-settings-modal,
  &.toggle-account-modal,
  &.custom-request-modal,
  &.marketdata-request-modal,
  &.edit-risk-setting-modal {
    width: 740px;

    .left-side {
      width: 30%;
      margin-top: 12px;
    }

    .modal-row {
      margin: 21px 0;

      &.first {
        margin-top: 0;
      }
    }

    hr {
      border-color: c.$deepest-blue-4;
      opacity: 1;
      margin: 0;
    }
  }

  &.marketdata-request-modal {
    width: 800px;

    .modal-inner-body {
      padding: 20px;
    }

    .left-side {
      margin-top: 0;
      width: fit-content;
      color: c.$deepest-blue-9;
      font-weight: 300;
    }

    .modal-row {
      margin: 0;
      gap: 10px;
      display: flex;
      align-items: center;
    }
  }

  .request-dropdown {
    >div {
      outline: none;

      >span {
        background: transparent;
        border-radius: 0;
        border-color: c.$deepest-blue-4;
        padding: 11px 52px 11px 16px;
        width: 100%;
        color: c.$white;
        transition: 0.3s linear;

        &.request-dropdown__placeholder {
          color: c.$deepest-blue-7;
        }
      }

      >div:nth-child(3) {
        border-radius: 0;
        background: c.$deepest-blue-2;
        color: c.$white;

        >div div {

          &:hover,
          &:focus {
            background-color: c.$deepest-blue-3;
          }
        }
      }
    }
  }

  .disable-row {
    opacity: 0.5;
    pointer-events: none;
  }

  &.toggle-account-modal {

    .modal-inner-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 40px 63px 0;

      form {
        padding-bottom: 20px;
      }
    }

    .modal-inner-footer {
      padding-bottom: 40px;
    }

    .react-calendar__month-view__days {
      .react-calendar__tile {

        &:disabled,
        &[disabled] {
          opacity: 0.1;
        }
      }
    }

    .calendar-container {

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .calendar-label {
        >div {
          >input {
            transition: 0.3s linear;

            &:focus,
            &:hover {
              border: 1px solid c.$cool-gray-7;
            }
          }

          >span {
            display: none;
          }
        }
      }
    }
  }

  &.risk-settings-modal,
  &.toggle-account-modal {

    .modal-inner-footer {
      padding-bottom: 40px;
    }

    .modal-inner-body {
      padding: 20px 63px 0;
    }

    .left-side {

      &+div {
        width: 70%;

        &.change-value {
          width: 34%;
          position: relative;
        }
      }
    }

    .select-category-dropdown {
      width: 82%;
    }

    .select-risk-settings-dropdown {
      width: 100%;
    }

    .current-price-row {
      .left-side {
        margin-top: 0;
      }
    }

    .category-value-tooltip {
      position: absolute;
      top: 12px;
      right: -39px;
    }
  }

  &.custom-request-modal {
    width: 680px;

    .modal-inner-body {
      padding: 39px 63px 0;

      form {
        padding-bottom: 20px;
      }
    }

    .modal-inner-footer {
      padding-bottom: 40px;
    }

    .upload-row {
      margin: 0 0 0 21%;
    }

    .left-side {
      width: 21%;

      &+div {
        width: 79%;
      }
    }

    .request-row {
      margin-bottom: 10px;
    }

    .request-dropdown {
      width: 55%;
    }

    .cabinet-input {
      width: 100%;
    }

    textarea {
      width: 100%;
      height: 180px;
    }

  }

  &.new-request-modal {

    .modal-inner-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 40px 63px 0;

      form hr {
        color: c.$deepest-blue-4;
      }
    }

    .modal-inner-footer {
      padding-bottom: 40px;
    }

    .left-side {
      width: 28%;
    }

    .left-side+div {
      width: 58%;
    }
  }


  &.edit-risk-setting-modal {

    .left-side {

      &+div {
        width: 70%;

        &.change-value {
          width: 34%;
          position: relative;
        }
      }
    }

    .current-price-row {
      .left-side {
        margin-top: 0;
      }
    }

    .category-value-tooltip {
      position: absolute;
      top: 12px;
      right: -39px;
    }
  }

}

.upload-tooltip-content,
.category-value-tooltip-content {
  z-index: 100;
  padding: 8px 30px 8px 12px;
  border: 1px solid c.$deepest-blue-5;
  background: c.$deepest-blue-2-primary;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 10px 20px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0;
  max-width: 275px;

  span {
    color: c.$white;
    @include fs-12;
  }

  ul {
    padding-left: 18px;
    margin: 0;
  }
}

.upload-tooltip,
.category-value-tooltip {
  display: flex;

  i {
    color: c.$deepest-blue-7;
    font-size: 22px;
  }
}

.modal-window {
  .request-overlay {
    background-color: c.$deepest-blue-2;
    opacity: 50%;
  }
}

.modal-window {
  .modal-inner-footer {
    button {
      width: 182px;

      span {
        color: c.$deepest-blue-2;
      }

      &:first-child {
        background-color: c.$new-leaf-green-5;

        &:hover {
          background-color: c.$new-leaf-green-4;
        }
      }

      // &.cancel-button {
      //   background-color: c.$red-vivid-6;

      //   &:hover {
      //     background-color: c.$red-vivid-5;
      //   }
      // }
    }
  }
}