@use "../../ui-kit/colors.scss" as c;
@use "../../ui-kit/devices.scss";
@import "../../ui-kit/mixins.scss";

.table-wrapper {
  position: relative;
  border-collapse: collapse;
  caption-side: bottom;

  thead {
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;

    tr {
      background-color: c.$deepest-blue-1;
      height: 48px;

      th {
        padding: 0 12px;
        padding-bottom: 10px !important;

        .column-title {
          display: flex;

          span {
            &:first-child {
              margin-right: 10px;

              &.ascending {
                transform: rotate(90deg);
              }
              &.descending {
                transform: rotate(270deg);
              }

              i {
                font-weight: bold;
              }
            }
            img {
              transform: rotateZ(90deg);
            }
          }
        }

        .select-filter-block {
          display: flex;
          padding: 5px;
          flex-direction: row;
          align-items: center;
          border: 1px solid c.$accent-color;
          border-radius: 5px;
        }
      }

      &:first-child {
        display: none;
      }
    }
  }

  tbody {
    tr {
      position: relative;

      .hover-content {
        position: absolute;
        padding: 0 !important;
        background: linear-gradient(
          to right,
          transparent 0%,
          c.$deepest-blue-4 60%,
          c.$deepest-blue-4 100%
        );
        color: white;
        opacity: 0;
        border: none !important;
        justify-content: flex-end;

        width: 140px;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        right: 1px;
        display: none; /* Hide it by default */
        align-items: center;
        z-index: 1;
      }

      &:hover .hover-content {
        display: flex;
        opacity: 1;
      }

      &:nth-child(even) {
        background: c.$deepest-blue-2;
      }
    }
  }

  .hide {
    display: none;
  }
}

.spinner {
  position: absolute;
  color: c.$accent-color !important;
  left: 18.5px;
  top: 6.5px;
  height: 1.2rem;
  width: 1.2rem;

  @include devices.desktop {
    left: 33.5px;
    top: 12.5px;
  }
}

.table-loading-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #06092241;
}

.account-overview-no-data-block {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: c.$deepest-blue-3;
  border: 1px solid c.$deepest-blue-4;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: c.$deepest-blue-7;
    @include fs-16;
  }
}

.star-column-item,
.checkbox-column-item {
  cursor: pointer;
}
