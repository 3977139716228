@use "../../ui-kit/colors.scss" as c;
@use "../../ui-kit/devices.scss";

.edit-profile-page-block {
  background-color: c.$deepest-blue-2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  overflow: auto;

  @include devices.desktop {
    padding: 0 1.5rem 0 1.5rem;
  }



  .edit-profile-taskbar {
    align-self: baseline;

    .title {
      text-decoration: underline;
    }
  }

  .no-image-mock {
    width: 100% !important;
    height: 250px;

    &::after {
      border: 3px solid c.$accent-color;
      border-radius: 10px;
    }
  }

  .card {
    align-items: flex-start;
    height: calc(100vh - 100px);
    background: transparent;
    border-radius: 0;
    border: 0;
    flex-direction: row;

    @include devices.desktop {
      flex-direction: row-reverse;
    }

    @include devices.mobile-resolution {
      flex-wrap: wrap;
      height: auto;
      align-items: center;
      justify-content: center;
      gap: 40px;
    }
  }

  .edit-profile-main-button {
    border-color: c.$new-leaf-green-5;
    padding: 10px 28px;
    color: c.$color-white;

    &:hover {
      background-color: c.$new-leaf-green-5-012;
    }

    &:active {
      background-color: c.$new-leaf-green-5-02;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
