@use "../../ui-kit/colors.scss" as c;
@import "../../ui-kit/mixins.scss";

.table-wrapper {
  width: 100%;
  @include fs-12;
  text-align: left;

  thead {
    @include fs-12;

    tr {
      th {
        background-color: c.$deepest-blue-1;
        padding: 0 12px !important;

        &:not(:first-child):not(:last-child) {
          border-left: 1px solid c.$deepest-blue-4;
          border-right: 1px solid c.$deepest-blue-4;
        }
      }
    }
  }

  tbody {
    tr {
      background-color: c.$deepest-blue-3;

      &:hover {
        background-color: c.$deepest-blue-4;

        td {
          border-color: c.$deepest-blue-3;
        }
      }
    }

    td {
      border: 1px solid c.$deepest-blue-4;
      padding: 12px !important;
    }
  }
}

.account-title {
  margin-right: auto;
  position: relative;
  font-family: "Syne";
  @include fs-16;
  color: c.$deepest-blue-8;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  i {
    margin-left: 5px;
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
