@use "../../ui-kit/colors.scss" as c;
@use "../../ui-kit/devices.scss";
@import "/src/components/ui-kit/mixins.scss";

.traders {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  z-index: 10;
}

.tpBlock {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tpItem {
  background-color: c.$deepest-blue-3;
  border: 1px solid c.$deepest-blue-4;
  padding: 12px 16px;
  cursor: pointer;
  @include fs-16;

  &.active-trading-platform {
    border: 3px solid c.$new-leaf-green-5;
    color: #f0f4f8;
  }

  &:hover {
    background-color: c.$deepest-blue-5;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -80px;

  button {
    margin: 0;
    margin-bottom: 20px;
  }
}

.table-body {
  display: flex;
  max-width: calc(100vw - 300px);
  max-height: calc(100vh - 260px);
  overflow: auto;
  position: relative;
  min-height: 150px;

  table {
    height: fit-content;
  }
}
